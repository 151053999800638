
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1+Code&family=M+PLUS+1:wght@100..900&family=Noto+Serif+JP:wght@300&family=Zen+Kaku+Gothic+Antique:wght@300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Zen+Kurenaido&display=swap');
body {
  margin: 0;
  font-family: "M PLUS 1", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    /* font-family:  "Zen Kurenaido","M PLUS 1", 'Roboto',sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
