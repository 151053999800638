.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* --------------------------------------------- */
/* --------------------------------------------- */
/* --------------------------------------------- */
/* --------------------------------------------- */
/* --------------------------------------------- */

@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1+Code&family=M+PLUS+1:wght@100..900&family=Noto+Serif+JP:wght@300&family=Zen+Kaku+Gothic+Antique:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


:root { 
  /* 濃い */
  --color-primary: #d499a6;
  --color-secondary: #ecd082;
  --color-tertiary: #a0c7e5;

  /* 薄い */
  /* --color-primary: #d4b4bb;
  --color-secondary: #e6dab9;
  --color-tertiary: #b9cfe0; */
  
  --color-primary-shadow: #d4d4d1;
  --background-color-primary: #ffffff;
  --background-color-secondary: snow;
}

html{
  background-color: var(--background-color-primary);
  /* font-family: 'Roboto',"M PLUS 1", sans-serif; */
}
body {
  font-family:  'Roboto',"M PLUS 1", sans-serif;
}

.app-body {
  margin-top: 8vh;;
}


.pageTitle{
  text-align: center;
  color: darkgray;
}

.contentWrapper{
  width:92%;
  margin: 1em auto;
  border-radius: 3%;
  background-color: var(--background-color-secondary);
}

.contentsArea{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sectionTitle{
  text-align: center;
  margin: 0 auto;
  padding-top: 1rem;
}

/* ヘッダー*/
.headerContentArea{
  margin: 0 auto;
}
.headerLogo{
  /* height: 40px; */
  height: 5vh;
  text-align: center;
}


/* ページ遷移 */
.bodyContentsWrap{
  animation: fadeinBodyContent 1s forwards;
}


@media screen and (min-width: 600px) {
  .sectionWrapper{
    display:flex;
  }
  .mainSection{
    width:20%;
  }
  .subSection{
    width:40%;
  }
  .bunnerSection{
    width:40%;
  }
}



@keyframes fadeinBodyContent {
  0% {opacity: 0}
  100% {opacity: 1}
}

/* ロゴ初回表示 start */
.logo-container {
	background: #FFF;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 999;
  transition: opacity 2s ease-in-out;
}

.logo-img {
  max-width: 350px;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999;
  transition: opacity 2s ease-in-out;
  opacity: 0;
}

.logo-img.fade-in {
  opacity: 1;
}

.logo-container.fade-out {
  opacity: 0;
}

.logo-container.finished {
  display: none;
}

/* ロゴ初回表示 end */

/* お知らせモーダル */
.tree-information-modal{
  /* animation-name:fadeRightAnime; */
  animation-duration:0.6s;
  animation-fill-mode:forwards;
  opacity:0;
}
.animetion-right{
  animation-name:fadeRightAnime;
}
.animetion-left{
  animation-name:fadeLeftAnime;
}
  @keyframes fadeRightAnime{
    from {
      opacity: 0;
      transform: translateX(100px);
    }
  
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes fadeLeftAnime{
    from {
      opacity: 0;
    transform: translateX(-100px);
    }
  
    to {
      opacity: 1;
    transform: translateX(0);
    }
  }