
@font-face {
	font-family: 'barcode-font';
	src: url(../../font/AFSNW-7.ttf);
}

.qrPage{
    background-color: rgb(236, 236, 236);
    padding: 3% 0;
}
.qrArea{
    text-align: center;
    width: 80%;
    background-color: snow;
    border-radius: 10%;
    margin: 3% auto;
    aspect-ratio: 1/1.1;
}
.qrCode{
    text-align: center;
    width: 55%;
    margin:  40px auto 0px auto;
}
.patientInfo{
    height: 1.5rem;
}

.listItem{
    display: flex;
    width: 100%;
}
.listItemEle{
    width: 50%;
}

.barCode{
    font-family: barcode-font;
    font-size: 25px;
    transform: scale(1, 2);
}