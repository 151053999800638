#barcode-scanner{
    width: '70%';
    height: '50%';
     margin:'0 auto';
    object-fit: 'cover';
}

#barcode-scanner > video, #barcode-scanner > canvas {
    width: 100%;
}
#barcode-scanner > canvas {
    position: absolute;
    left: 0px; top: 0px;
}