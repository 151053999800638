.app-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* ページの他の要素よりも手前に表示されるようにz-indexを設定 */
  }

.loginButon{
    height:80% !important;
    min-width:10% !important;
    /* background-color: rgb(139, 162, 226) !important; */
    border-radius: 5% !important;
    font-size: 0.5rem !important;
    opacity: 0.8 !important;
    display: inline-block !important;
    width: 100% !important;
    max-width: 350px; /* ボタン幅 */
    border: 2px solid  !important; /* 線幅・種類・色 */
    background: #fff !important; /* 背景色 */
    padding: 0.5em 1em !important;
    font-weight: bold !important; /* 文字の太さ */
    transition: 0.3s;
}


.login{
    color: #4dc174 !important; /* 文字色 */
    border-color: #4dc174 !important;
}

.logout{
    color: #4D9BC1 !important; /* 文字色 */
    border-color: #4D9BC1 !important;
}
