
.gallery{
  columns: 4;/*段組みの数*/
  padding:0 15px;/*ギャラリー左右に余白をつける*/
  }
  
  .gallery li {
      margin-bottom: 20px;/*各画像下に余白をつける*/
  }
  
  /*ギャラリー内のイメージは横幅100%にする*/
  .gallery img{
    width:100%;
    height:auto;
    vertical-align: bottom;/*画像の下にできる余白を削除*/}
  
  /*　横幅900px以下の段組み設定　*/
  @media only screen and (max-width: 900px) {
    .gallery{
    columns:3;
    } 
  }
  
  @media only screen and (max-width: 768px) {
    .gallery{
    columns: 2;
    } 
  }

/*==================================================
ボンッ
===================================*/

/* 拡大 */
.zoomIn{
  animation-name:zoomInAnime;
  animation-duration:0.5s;
  animation-fill-mode:forwards;
}

@keyframes zoomInAnime{
  from {
  transform: scale(0.6);
  }

  to {
      transform: scale(1);
  }
}


  /*========= レイアウトのためのCSS ===============*/

ul{
  margin:0;
  padding: 0;
  list-style: none;
}

a{
  color: #333;
}



/* ============== モーダル ============= */
.modal {
  position: fixed; /* 画面上に固定 */
  z-index: 1; /* 最前面に表示 */
  left: 0;
  top: 0;
  width: 100%; /* 画面幅いっぱいに広がる */
  height: 100%; /* 画面高さいっぱいに広がる */
  background-color: rgba(0, 0, 0, 0.8); /* 背景色（黒） */
  display: flex; /* 中央に配置するために必要 */
  justify-content: center; /* 横方向に中央揃え */
  align-items: center; /* 縦方向に中央揃え */
}

.modal-content {
  position: relative;
  background-color: #fefefe; /* モーダルの背景色 */
  margin: auto; /* 自動的に中央揃え */
  padding: 20px;
  border: 1px solid #888;
  max-width: 90%; /* 最大幅 */
  max-height: 90%; /* 最大高さ */
  overflow: auto; /* 高さが足りない場合にスクロールバーを表示 */
}

.modal-content img {
  width: 100%; /* 画面幅いっぱいに広がる */
  height: auto; /* 高さは自動調整 */
}


/* ロゴ表示 start */
/* .GallaryLogoContainer {
  background-color: snow;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 999;
  transition: opacity 2s ease-in-out;
}

.GallaryLogoImg {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 9999;
  object-fit: cover;
}

.GallaryLogoContainer.fade-in {
  opacity: 1;
}

.GallaryLogoContainer.fade-out {
  opacity: 0;
}

.GallaryLogoContainer.finished {
  display: none;
} */


/* ロゴ初回表示 end */


/* ロゴ初回表示 topと同じ start */
.GallaryLogoContainer {
  background-color: #ffffff;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 999;
  transition: opacity 2s ease-in-out;
}

.GallaryLogoImg {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 9999;
  object-fit: cover;
  transition: opacity 2s ease-in-out;
  opacity: 0;
}

.GallaryLogoImg.fade-in {
  opacity: 1;
}

.GallaryLogoContainer.fade-out {
  opacity: 0;
}

.GallaryLogoContainer.finished {
  display: none;
}

/* ロゴ初回表示 topと同じ end */

