
a.menuButton  {
  border-radius: 5%;
  line-height: 100%;
  text-align: center;
  aspect-ratio: 1;
  padding: 0;
  margin:3% auto;
  -webkit-box-shadow: 0 5px 0 var(--color-primary-shadow);
  box-shadow: 0 5px 0 var(--color-primary-shadow);
  /* background-color: var(--color-primary); */
}

a.menuButton :hover {
  -webkit-transform: translate(0, 3px);
  transform: translate(0, 3px);
  -webkit-box-shadow: 0 2px 0 var(--color-primary-shadow);
  box-shadow: 0 2px 0 var(--color-primary-shadow);
}

.menuButton img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

a.mainMenuButton{
  width:40%;
  background-color: #F0E9D2;
}

a.subMenuButton{
  width:28%;
}

a.bunnerMenuButton{
  width:48%;
  height: 8vh;
}

.primary{
  background-color: var(--color-primary);
}

.secondary{
  background-color: var(--color-secondary);
}

.tertiary{
  background-color: var(--color-tertiary);
}

@media screen and (min-width: 600px) {
  a.mainMenuButton{
    width:100%;
    background-color: #F0E9D2;
  }
  
  a.subMenuButton{
    width:30%;
  }
  
  a.bunnerMenuButton{
    width:90%;
    height: 16vh;
  }
}