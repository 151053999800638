.patientCallListTitle{
    color: gray;
    text-align: center;
    font-size: 50px;
}

.patientCallListContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5列に設定 */
    gap: 10px; /* アイテム間の間隔 */
    padding: 10px;
}
  
.patientCallListItem {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    text-align: center;
    font-size: 80px;
    color: gray;
    width: 95%; /* グリッドの幅に合わせる */
    border: 2px solid lightgray;
    border-radius: 10px;
    padding: 10px;
    background-color: snow;
    height: 22vh;
    /* aspect-ratio: 1 1.5; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    white-space: pre-wrap; /* 空白や改行で折り返し */
    word-break: break-all; /* 単語の途中でも折り返し */
}

  
@keyframes blink {
    0%, 50%, 100% {
      opacity: 1;
      background-color: snow; /* 点滅時の背景色 */
    }
    25%, 75% {
      opacity: 0;
      background-color: yellow; /* 点滅時の背景色 */
    }
  }
  
  .blink {
    animation: blink 3s linear infinite;
  }