.signIn_header{
  background-size: contain;
  background-image: url('../../../public/img/logo.png');
  width: 180px;
  height: 160px;
  margin: 5% auto 30px auto;
}

.signin_wrapper{
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100vw;
  padding: 10px;
}

.signin_content{
  margin: 0px auto;
}

.signIn_body div{
  margin-bottom: 5px;
}

.signin_field{
  width: 80%;
}

.signin_button{
  margin-top: 20px !important;
  width: 80%;
}

.short{
  width: 38% !important;
  margin: 0 3% !important;

}