.buttonArea{
    display: flex;
}

.clinicButton{
    width:25% !important;
    background-color:rgb(241, 205, 211) !important;
}

.selectedButton{
    background-color: var(--color-tertiary) !important;
    color: snow !important;
    font-weight: bolder !important;
    border-bottom: 3px solid rgb(103, 128, 240) !important;
}

.iframeWrapper{
    height: calc(100vh - 100px);
}